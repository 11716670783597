﻿import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Utils } from '../services/utils';

@Directive({
  selector: '[hdcHide]'
})
export class HdcHideDirective implements OnInit {

  private _utils = new Utils();

  constructor(private templateRef: TemplateRef<any>, private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    if (!this._utils.isHdc()) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }

}
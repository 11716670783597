import { Component } from '@angular/core';
import { ApiService } from '../../../services/api-service';
import { ComponentBase } from '../../component-base';
import { Utils } from '../../../services/utils';

@Component({
  selector: 'app-page-finish',
  templateUrl: 'finish.component.html',
  styleUrls: []
})
export class FinishComponent extends ComponentBase {

    private _utils = new Utils();
    hideSurvey: boolean = false;
    showPublicSite: boolean = false;

  constructor(private _apiService: ApiService) {
    super();
      this.hideSurvey = this._apiService.hasRequestedCallback.value || this._apiService.hasCompletedSurvey.value || this._apiService.hideSurvey;
      var hash = window.location.hash;
      this.showPublicSite = this._utils.getUrlParameter(hash, "fromPublicSite", "false").toLowerCase() == "true";
  }

}
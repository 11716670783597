import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'form-errors',
  templateUrl: 'form-errors.component.html',
  styleUrls: []
})
export class FormErrorsComponent extends ComponentBase {

  @Input() control: FormControl;
  @Input() patternText?: string;
  @Input() requiredText?: string;
  @Input() minlengthText?: string;
  @Input() maxlengthText?: string;
  @Input() emailText?: string;

  constructor() {
    super();
  }

}
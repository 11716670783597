import { Injectable } from "@angular/core";
import { Utils } from "../services/utils";
import { Alert } from "../models/alert.model";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { AlertsService } from "./alerts-service";
import { BehaviorSubject } from "rxjs";
import { environment } from "../environments/environment";
import { CookieService } from '../services/cookie-service';
import { ActivatedRoute } from "@angular/router";

/**
 * @property {string} emailAddress The customer's email address
 */
export interface RarRequest {
  emailAddress: string;
  idNumber?: string;
}

/**
 * @property {string} phoneNumber The customer's phone number
 * @property {string} firstName The customer's first name
 * @property {string} lastName The customer's last name
 * @property {string} callbackDay The selected callback day, either "today" or "tomorrow"
 * @property {string} callbackWindow The selected callback window, an object consisting of callback start time and callback end time
 * 
 */
export interface CallbackRequest {
  phoneNumber: string;
  firstName: string;
  lastName: string;
  callbackDay: string;
  callbackWindowStart: string;
  callbackWindowEnd: string;
}

/**
 * @property {number} surveyHelpful A number representing the users 'How helpful did you find the website' choice
 */
export interface SurveySimpleRequest {
  surveyHelpful: number
}

/**
 * @property {number} surveyExperience A number representing the users 'How was your experience?' choice
 * @property {number} surveyReturn A number representing the users 'How likely are you to return' choice
 * @property {number} surveySelf A number representing the users 'How would you describe yourself?' choice
 */
export interface SurveyExtendedRequest {
  surveyExperience: number,
  surveyExperienceOther?: string,
  surveyReturn: number
  surveySelf: number
}

/**
 * @property {string} startTime A string with the UTC datetime for the start of the callback window
 * @property {string} endTime A string with the UTC datetime for the end of the callback window
 */
export interface CallbackSlot {
  startTime: string
  endTime: string
}

/**
 * @property {string} reportGuid A Guid string representing the ID of the report to download
 * @property {string} recaptchaResponse The response from the Recaptcha module
 */
export interface DownloadRequest {
  reportGuid: string,
  recaptchaResponse: string,
  lang: string
}


@Injectable()
/**
 * Creates an instance of the ApiService
 * @class
 * @classdesc A service providing the API interface required by the app using live data.
 */
export class ApiService {
  private _httpHeaders: HttpHeaders;

  private _transactionId: string;

  private _hasValidId: boolean = false;
  hasValidId: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _sessionId: string;
  get sessionId(): string {
    return this._sessionId;
  }

  private _hasId: boolean = false;
  get hasId(): boolean {
    return this._hasId;
  }

  /**
   * @property {boolean} _displayCallback Whether or not to display the callback section on further steps
   */
  private _displayCallback: boolean;
  displayCallback: BehaviorSubject<boolean> = new BehaviorSubject(true);

  /**
   * @property {boolean} _hasCompletedSurvey Whether or not the user has completed the survey already
   */
  private _hasCompletedSurvey: boolean;
  hasCompletedSurvey: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * @property {boolean} _hideSurvey Whether or not to hide the survey app-wide
   */
  private _hideSurvey: boolean = false;
  get hideSurvey(): boolean {
    return this._hideSurvey;
  }

  private _hasRequestedRar: boolean;
  hasRequestedRar: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _hasRequestedCallback: boolean;
  hasRequestedCallback: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private _loaded: boolean = false;
  loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  showFaq: BehaviorSubject<boolean> = new BehaviorSubject(true);

  public languages: {
    key: string,
    value: string
  }[];

  private _selectedLang: string;
  selectedLang: BehaviorSubject<string> = new BehaviorSubject("");

  private _displayExpiryTimeout: any;
  private _expiryRefreshTimeout: any;

  private _displayExpiry: number;
  displayExpiry: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * Creates an instance of the ApiService
   * @param _utils The utility service
   */
  constructor(
    private _utils: Utils,
    public http: HttpClient,
    private _translateService: TranslateService,
    private _alertsService: AlertsService,
    private _cookieService: CookieService,
    private _route: ActivatedRoute) {
    this._httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    this.languages = [
      {
        key: "en-US",
        value: "English (United States)"
      },
      {
        key: "es-US",
        value: "Espa\u00F1ol (Estados Unidos)"
      },
      {
        key: "fr-CA",
        value: "fran\u00E7ais (Canada)"
      }
    ];

    if (window.location.hash && window.location.hash.indexOf("/download") > -1) {
      this._loaded = true;
      this.loaded.next(this._loaded);
    } else {
      this.fetchSession();
    }
  }

  makeParameters(parameterName: string, parameterValue: string) {
    let params = {};
    params[parameterName] = parameterValue;
    return params;
  }

  getWithSearchParams(connection: string, parameterName: string, parameterValue: string) {
    let params = this.makeParameters(parameterName, parameterValue);
    let options = {
      headers: this._httpHeaders,
      search: params
    };

    return this.get(connection, options);
  }

  postWithSearchParams(connection: string, parameterName: string, parameterValue: string) {
    let params = this.makeParameters(parameterName, parameterValue);
    let options = {
      headers: this._httpHeaders,
      search: params
    };

    return this.post(connection, null, options);
  }

  get(url: string, options: object) {
    let promise = new Promise<any>((resolve, reject) => {
      this.http.get(url, options).toPromise().then(response => {
        resolve(response);
      }, error => {
        console.error(error);
        reject(error);
      });
    });

    return promise;
  }

  post(url: string, body: object, options: object) {
    let promise = new Promise<any>((resolve, reject) => {
      this.http.post(url, body, options).toPromise().then(response => {
        resolve(response);
      }, error => {
        console.error(error);
        reject(error);
      });
    });

    return promise;
  }

  /**
   * Creates a timeout to display a window warning the user they will be logged off in 5 minutes
   */
  private updateDisplayExpiryTimeout() {
    if (this._displayExpiry >= 2){
      return;
    }

    this._displayExpiry = 0;
    this.displayExpiry.next(this._displayExpiry);

    if (this._displayExpiryTimeout !== null) {
      clearTimeout(this._displayExpiryTimeout);
    }

    if (this._expiryRefreshTimeout !== null) {
      clearTimeout(this._expiryRefreshTimeout);
    }

    this._displayExpiryTimeout = setTimeout(() => {
      this._displayExpiry = 1;
      this.displayExpiry.next(this._displayExpiry);

      this._expiryRefreshTimeout = setTimeout(() => {
        this._displayExpiry = 2;
        this.displayExpiry.next(this._displayExpiry);
      }, (1000 * 60)); // after 1 minute, block the page and force refresh
    }, (1000 * 60 * environment.inactiveTimeoutMins)); // run after the configured number of minutes
    //}, 5000); // run in 5 seconds
  }

  /**
   * Refreshes a users session to 30 minutes after an action has been taken.
   */
  refreshSession() {
    // give another 30 minutes until expiry after an action is taken
    if (!this._utils.nullOrEmpty(this._sessionId)) {
      this._cookieService.setWithExpiryInMinutes('vshp_sid', this._sessionId, 30);
      this.updateDisplayExpiryTimeout();
    }
  }

  /**
   * Fetches a session ID from the server and stores it in a cookie.
   */
  fetchSession(): void {
    // try get session ID from cookie
    let prevId = this._cookieService.get('vshp_sid');
    if (prevId !== "") {
      this._sessionId = prevId;
      this.refreshSession();
      this._loaded = true;
      this.loaded.next(this._loaded);
    } else {
      // otherwise get the session id from server

      this.get(`${environment.apiEndpoint}/telemetry/session`, {
        headers: this._httpHeaders
      }).then(response => {
        if (typeof response.sessionId === "string") {
          this._sessionId = response.sessionId;

          // open a 30 minute window to take actions
          this._cookieService.setWithExpiryInMinutes('vshp_sid', this._sessionId, 30);
          this.updateDisplayExpiryTimeout();

          this._loaded = true;
          this.loaded.next(this._loaded);
        } else {
          this._alertsService.add("error.generic", "e");
        }
      }, () => {
        this._alertsService.add("error.generic", "e");
      });
    }
  }

  /**
   * Validates an inputted transaction ID
   * @param transactionId The input transaction ID to vaidate.
   * @returns {Promise} with an error message.
   */
  validateTransactionId(transactionId: string, recaptchaResponse: string): Promise<any> {
      let promise = new Promise<void>((resolve, reject) => {
      this.refreshSession();

      if (this._loaded === false) {
        reject(new Alert("error.loading", "e"));
      }

      if (transactionId === null || transactionId.length === 0 || !/^[a-zA-Z0-9-]*$/.test(transactionId)) {
        reject(new Alert("error.invalidTransactionId", "w"));
      }

      let data = {
        transactionId: transactionId.replace("-", ""),
        recaptchaResponse: recaptchaResponse,
        sessionId: this._sessionId,
        lang: this.getCurrentLanguage()
      };

      let options = {
        headers: this._httpHeaders
      };

      this.post(`${environment.apiEndpoint}/home/transaction`, data, options).then(response => {
        if (response.valid === false) {
          if (response.lockout === true) {
            reject(new Alert("error.lockout", "w"));
          } else {
            reject(new Alert(response.responseMessage, "w"));
          }
        } else {
          this._transactionId = transactionId;
          this._displayCallback = !!response.displayCallback;
          this._hasId = !!response.hasId;
          this._hasCompletedSurvey = !!response.hasCompletedSurvey;
          // if they've already submitted a survey from new session, do not show the survey
          // this is to prevent the survey disappearing after submitting
          this._hideSurvey = !!response.hasCompletedSurvey;
          this._hasRequestedRar = !!response.hasRequestedRar;
          this._hasRequestedCallback = !!response.hasRequestedCallback;
          this._hasValidId = true;

          // broadcast new values
          this.displayCallback.next(this._displayCallback);
          this.hasValidId.next(this._hasValidId);
          this.hasCompletedSurvey.next(this._hasCompletedSurvey);
          this.hasRequestedCallback.next(this._hasRequestedCallback);
          this.hasRequestedRar.next(this._hasRequestedRar);
          resolve();
        }
      }, () => {
        this._hasValidId = false;
        this.hasValidId.next(this._hasValidId);
        reject(new Alert("error.generic", "e"));
      });
    });

    return promise;
  }

  /**
   * Requests an RAR
   * @param {RarRequest} data The data to make the RAR request, currently an object consisting of the property emailAddress
   * @returns {Promise} A promise indicating the success or failure of the POST.
   */
  requestRar(request: RarRequest): Promise<any> {
      let result = new Promise<void>((resolve, reject) => {
      this.refreshSession();

      if (this._loaded === false) {
        reject(new Alert("error.loading", "e"));
      }
      if (this._utils.nullOrEmpty(request.emailAddress)) {
        let alert = new Alert("validation.emailMissing", "w");
        reject(alert);
      }
      if (environment.EMAIL_REGEX.test(request.emailAddress) === false) {
        let alert = new Alert("validation.emailInvalid", "w");
        reject(alert);
      }

      let data = {
        transactionId: this._transactionId,
        idNumber: null,
        lang: this.getCurrentLanguage(),
        emailAddress: request.emailAddress,
        sessionId: this._sessionId
      };

      if (this._hasId) {
        if (request.idNumber === null) {
          reject(new Alert("validation.id", "e"));
        }
        data.idNumber = request.idNumber;
      }

      let options = {
        headers: this._httpHeaders
      };

      this.post(`${environment.apiEndpoint}/furthersteps/rar`, data, options).then(response => {
        if (response.valid) {
          resolve();
        } else {
          if (response.invalidId) {
            reject(new Alert("furthersteps.rar.error.invalidId", "w"));
          } else {
            reject(new Alert("error.generic", "w"));
          }
        }
      }, () => {
        reject(new Alert("error.generic", "e"));
      });
    });
    return result;
  }

  /**
   * Fetches the currently available callback windows for the current day and the following day.
   * @returns {Promise} A promise, resolving the callback windows or rejecting with an error message.
   */
  getCallbackWindows(): Promise<any> {
    let result = new Promise((resolve, reject) => {
      this.refreshSession();

      if (this._loaded === false) {
        reject(new Alert("error.loading", "e"));
      }
      this.post(`${environment.apiEndpoint}/callback/getcallbackwindows`,
        {
          lang: "en",
          sessionId: this._sessionId
        }, {
          headers: this._httpHeaders
        }).then(response => {
          resolve(response);
        }, () => {
          resolve(new Alert("error.generic", "e"));
        });
    });
    return result;
  }

  /**
   * Requests a callback for a customer, from their inputted data
   * @param {CallbackRequest} data The data to send to the server to make the callback request.
   * @returns {Promise} A promise, resolving with a success message or rejecting with a validation or error message.
   */
  requestCallback(request: CallbackRequest): Promise<any> {
      let result = new Promise<void>((resolve, reject) => {
      this.refreshSession();

      if (this._loaded === false) {
        reject(new Alert("error.loading", "e"));
      }
      // if we've already requested a callback, prevent another callback being requested
      if (this._hasRequestedCallback) {
        reject(new Alert("error.callbackRequested", "e"));
      }

      let phoneNumberTrim = request.phoneNumber.replace(/[\s-]+/, "");

      if (this._utils.nullOrEmpty(request.firstName)) {
        let alert = new Alert("validation.firstName", "w");
        reject(alert);
      }
      if (this._utils.nullOrEmpty(request.lastName)) {
        let alert = new Alert("validation.lastName", "w");
        reject(alert);
      }
      if (this._utils.nullOrEmpty(phoneNumberTrim) || this._utils.isNumber(phoneNumberTrim) === false) {
        let alert = new Alert("validation.phoneNumber", "w");
        reject(alert);
      }
      if (this._utils.nullOrEmpty(request.callbackDay)) {
        let alert = new Alert("validation.callbackDay", "w");
        reject(alert);
      }
      if (this._utils.nullOrEmpty(request.callbackWindowStart)) {
        let alert = new Alert("validation.callbackWindow", "w");
        reject(alert);
      }
      if (this._utils.nullOrEmpty(request.callbackWindowEnd)) {
        let alert = new Alert("validation.callbackWindow", "w");
        reject(alert);
      }

      let data = {
        phoneNumber: phoneNumberTrim,
        firstName: request.firstName,
        lastName: request.lastName,
        callbackWindowStart: request.callbackWindowStart,
        callbackWindowEnd: request.callbackWindowEnd,
        transactionId: this._transactionId,
        lang: this.getCurrentLanguage(),
        sessionId: this._sessionId
      };

      let options = {
        headers: this._httpHeaders
      };

      this.post(`${environment.apiEndpoint}/callback/requestcallback`, data, options).then(response => {
        if (response.valid) {
          this._hasRequestedCallback = true;
          this.hasRequestedCallback.next(this._hasRequestedCallback);
          resolve();
        } else {
          reject(new Alert("error.generic", "e"));
        }
      }, () => {
        reject(new Alert("error.generic", "e"));
      });
    });
    return result;
  }

  /**
   * Sends the first survey question
   * @param {SurveySimpleRequest} request The survey request
   */
  sendSurveySimple(request: SurveySimpleRequest) {
      let promise = new Promise<void>((resolve, reject) => {
      this.refreshSession();

      if (this._loaded === false) {
        reject(new Alert("error.loading", "e"));
      }

      if (this._hasCompletedSurvey) {
        reject(new Alert("error.surveyCompleted", "e"));
      }

      if (typeof request.surveyHelpful !== "number" || isNaN(request.surveyHelpful)) {
        reject(new Alert("survey.error", "e"));
      }

      let data = {
        transactionId: this._transactionId,
        sessionId: this.sessionId,
        surveyQuestions: [{
          questionId: 1,
          answerid: request.surveyHelpful
        }]
      }

      let options = {
        headers: this._httpHeaders
      };

      this.post(`${environment.apiEndpoint}/survey/postsurvey`, data, options).then(() => {
        resolve();
      }, () => {
        reject(new Alert("error.generic", "e"));
      });
    });
    return promise;
  }

  /**
   * Sends the rest of the survey questions
   * @param {SurveySimpleRequest} request The survey request
   */
  sendSurveyExtended(request: SurveyExtendedRequest): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      this.refreshSession();

      if (this._loaded === false) {
        reject(new Alert("error.loading", "e"));
      }


      if (this._hasCompletedSurvey) {
        reject(new Alert("error.surveyCompleted", "e"));
      }

      if (typeof request.surveyExperience !== "number" || Number.isNaN(request.surveyExperience)
        || typeof request.surveyReturn !== "number" || Number.isNaN(request.surveyReturn)
        || typeof request.surveySelf !== "number" || Number.isNaN(request.surveySelf)) {
        reject(new Alert("survey.error", "e"));
      }

      if (request.surveyExperience === 0 || request.surveyReturn === 0 || request.surveySelf === 0) {
        reject(new Alert("survey.missingFields", "w"));
      }

      let data = {
        transactionId: this._transactionId,
        sessionId: this._sessionId,
        surveyQuestions: [{
          questionId: 2,
          answerid: request.surveyExperience,
          answerUserText: null
        }, {
          questionId: 3,
          answerid: request.surveyReturn
        }, {
          questionId: 4,
          answerid: request.surveySelf
        }]
      }

      if (!this._utils.nullOrEmpty(request.surveyExperienceOther)) {
        data.surveyQuestions[0].answerUserText = request.surveyExperienceOther;
      }

      let options = {
        headers: this._httpHeaders
      };

      this.post(`${environment.apiEndpoint}/survey/postsurvey`, data, options).then(() => {
        this._hasCompletedSurvey = true;
        this.hasCompletedSurvey.next(this._hasCompletedSurvey);
        resolve();
      }, () => {
        reject(new Alert("error.generic", "e"));
      });
    });
    return promise;
  }

  /**
   * Sends a request to download the RAR, from the URL a user recieves via email
   * @param data 
   */
  downloadRar(data: DownloadRequest): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      let options = {
        headers: this._httpHeaders
      }

      this.post(`${environment.apiEndpoint}/download/validate`, data, options).then(response => {
        if (response['error.captcha.invalid']) {
          reject(new Alert("download.error.captcha.invalid", "w"));
        }
        if (response['error.captcha.timeout']) {
          reject(new Alert("download.error.captcha.timeout", "w"));
        }
        if (response.valid) {
          resolve(`${environment.apiEndpoint}/download/downloadRar?reportGuid=${data.reportGuid}&sessionId=${this._sessionId}`);
        } else {
          reject(new Alert("download.error.invalid", "w"));
        }
      }, () => {
        reject(new Alert("error.generic", "e"));
      });
    });
    return promise;
  }

  /**
   * Sets the language of the application
   * @param language The language code to use (en-US or es-US - requires _exact_ key)
   */
  useLanguage(language: string) {
    for (var i = 0, ii = this.languages.length; i < ii; i++) {
      if (this.languages[i].key === language) {
        this._cookieService.setWithExpiryInYears("culture", language, 1);
        this._translateService.use(language);
        this._selectedLang = language;
        this.selectedLang.next(language);
      }
    }
  }

  /**
   * Sets up the default language using the user's browser language if possible. Uses fuzzy matching (i.e. es matches es-US)
   */
  setDefaultLanguage(): string {

    this._translateService.setDefaultLang("en-US");

    //fetch the culture from the "query string", if present. I couldn't find a way for Angular to tell me what the parameters are without using a subscription, which isn't good because I need the values now
    var queryStringValue = null;
    var hash = window.location.hash; //in Angular the "query string parameters" actually go after the hashtag
    if (hash.indexOf('?') >= 0) { //if there are any parameters specified
      hash = hash.substring(hash.indexOf('?') + 1);
      var params = hash.split('&');
      for (var i = 0; i < params.length; i++) {
        var param = params[i];
        if (param.indexOf('culture') === 0) {
          queryStringValue = param.split('=')[1];
          break;
        }
      }
    }

    var cookieValue = this._cookieService.get("culture");

    if (cookieValue != null && cookieValue.trim().length != 0) {
      this._selectedLang = cookieValue;
    } else if (queryStringValue) {
      this._selectedLang = queryStringValue;
    } else {
      let browserLang = this._translateService.getBrowserLang();
      let matches = this.languages.filter(item => item.key.startsWith(browserLang));
      if (matches.length) {
        this._selectedLang = matches[0].key;
      } else {
        this._selectedLang = "en-US";
      }

    }

    this._translateService.use(this._selectedLang);
    this.selectedLang.next(this._selectedLang);
    return this._selectedLang;

  }

  /**
   * Gets the current language of the application.
   */
  getCurrentLanguage(): string {
    return this._translateService.currentLang || this._translateService.getDefaultLang() || "en-US";
  }

}
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../services/api-service';
import { LightboxService } from '../../../services/lightbox-service';
import { Utils } from '../../../services/utils';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'app-page-denial',
  templateUrl: 'denial.component.html',
  styleUrls: []
})
export class DenialComponent extends ComponentBase {

  showFaq: boolean;
  returnPolicyUrl: string;

  private _utils = new Utils();

  constructor(private _lightboxService: LightboxService, private _apiService: ApiService, private _router: Router) {

    super();

    _apiService.showFaq.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      this.showFaq = value;
    });

    this.returnPolicyUrl = this._utils.isHdc() ? 'https://www.homedepot.ca/en/home/customer-support/return-policy.html' : 'https://www.homedepot.com/c/Return_Policy';

    }
    /*************************************************************************
     * Author:  KWOO
     * Date 2021-09-20
     * Summary:  Get the parameters to add to the url links.
     *************************************************************************/
    private getNavUrlParameters() {
        // Set the fromPublicSite parameters for the url if fromPublicSite exists in query parameters.
        var hash = window.location.hash;
        var retValue: string = this._utils.getUrlParameter(hash, "fromPublicSite", "false");
        var navUrlParameters: string = retValue.toLowerCase() === "true" ? "?fromPublicSite=true" : "";

        return navUrlParameters;
    }
  /**
   * Shows the FAQ Lightbox
   */
  showFaqLightbox(): void {
    this._lightboxService.toggle('faq');
  }

  /**
 * Redirects the user to the finish page.
 */
    public finish(): void {
      this._router.navigateByUrl('/finish' + this.getNavUrlParameters());
    }

    /**
    * Redirects the user to the furthersteps page.
    */
    public furtherSteps(): void {
        this._router.navigateByUrl('/furthersteps' + this.getNavUrlParameters());
    }

}
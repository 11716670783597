import { Component, Input } from '@angular/core';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'collapsible-panel',
  templateUrl: 'collapsible-panel.component.html',
  styleUrls: []
})
export class CollapsiblePanelComponent extends ComponentBase {

  @Input() panelTitle: string;
  @Input() recommended: boolean;
  @Input() id: string;
  @Input() collapsed: boolean = false;

  constructor() {
    super();
  }

  toggle(){
    this.collapsed = !this.collapsed;
  }

}
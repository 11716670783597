import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from '../components/pages/home/home.component';
import { HttpClientModule } from '@angular/common/http';
import { DenialComponent } from '../components/pages/denial/denial.component';
import { AuthGuard } from '../services/auth-guard';
import { FurtherStepsComponent } from '../components/pages/furthersteps/furthersteps.component';
import { DownloadComponent } from '../components/pages/download/download.component';
import { FinishComponent } from '../components/pages/finish/finish.component';

const routes: Routes = [
  { path: '',   component: HomeComponent },
  { path: 'denialreasons',   component: DenialComponent, canActivate: [AuthGuard]},
  { path: 'furthersteps', component: FurtherStepsComponent, canActivate: [AuthGuard]},
  { path: 'download/:reportGuid', component: DownloadComponent, canActivate: [AuthGuard] },
  { path: 'finish', component: FinishComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    HttpClientModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

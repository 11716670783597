import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { ComponentBase } from '../components/component-base';
import { ApiService } from '../services/api-service';
import { LightboxService } from '../services/lightbox-service';
import { OneTrustService } from '../services/onetrust-service';

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html'
})
export class AppComponent extends ComponentBase implements OnInit {

    timeoutFinished: boolean = false;
    timeoutCountdown: number = 0;

    languages: {
        key: string,
        value: string
    }[];

    selectedLang: string;
    loaded: boolean = false;

    constructor(private _apiService: ApiService, private _lightboxService: LightboxService, private _oneTrustService: OneTrustService) {

        super();

        this.languages = _apiService.languages;

        this.selectedLang = this._apiService.setDefaultLanguage();
        
        this.loaded = this._apiService.loaded.value;

    }

    ngOnInit() {
        this._apiService.loaded.pipe(takeUntil(this.destroyed$)).subscribe(loaded => {
            if (loaded === true) {
                this.loaded = true;
            }
        });

        this._apiService.selectedLang.pipe(takeUntil(this.destroyed$)).subscribe(next => {
            if (next !== this.selectedLang) {
                this.selectedLang = next;
            }

            // Force OneTrust to update its language settings
            document.querySelector('html').lang = this.selectedLang || 'en';
            this._oneTrustService.afterOneTrustLoaded.subscribe(OneTrust => {
                OneTrust.changeLanguage((this.selectedLang || 'en').split('-')[0]);
            });
        });

        this._apiService.displayExpiry.pipe(takeUntil(this.destroyed$)).subscribe(next => {
            if (next >= 2) {
                this.timeoutFinished = true;
            }
            else if (next == 1) {
                this._lightboxService.show('displayExpiry');
                this.timeoutCountdown = 60;
                // set a countdown on the button
                let s = setInterval(() => {
                    this.timeoutCountdown -= 1;
                    if (this.timeoutCountdown <= 0) {
                        clearInterval(s);
                    }
                }, 1000);
            } else {
                this._lightboxService.hide('displayExpiry');
            }
        });
    }
   
    useLanguage(language: string) {
        this._apiService.useLanguage(language);

        if (window.location.href.slice(-1) == "/") {
            window.location.reload();
        }
    }

    refreshDisplayExpiry() {
        this._apiService.refreshSession();
    }

    refreshPage() {
        window.location.reload();
    }

}
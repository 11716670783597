﻿import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '../services/utils';
import { Observable } from 'rxjs';

@Injectable()
export class ContextInterceptor implements HttpInterceptor {

  private _utils = new Utils();

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Add a custom header indicating which Home Depot region made this request
    request = request.clone({
      setHeaders: {
        HdRequestSource: this._utils.isHdc() ? 'HDC' : 'THD'
      }
    });

    return next.handle(request);

  }

}

import { FormControl } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[disableControl][disableCond]'
})
export class DisableControlDirective {
  @Input() disableControl: FormControl;

  constructor() { }

  get disableCond(): boolean {
    return !!this.disableControl && this.disableControl.disabled;
  }

  @Input('disableCond') set disableCond(s: boolean) {
    if (!this.disableControl) return;
    else if (s) this.disableControl.disable();
    else this.disableControl.enable();
  }
}
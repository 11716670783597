import { AsyncSubject } from "rxjs";

export class Utils {

    nullOrEmpty(input: any) {
        if (input === null || typeof input === "undefined" || (input.hasOwnProperty("length") && input.length === 0)) {
            return true;
        }
        return false;
    }

    isNumber(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    isHdc(): boolean {
        return window.location.href.match(/\.[Hh][Dd][Cc]\./) != null;
    }
    /*************************************************************************
     * Author:  KWOO
     * Date 2021-09-20
     * Summary:  Searches the url query string and returns a parameter string.
     *   defaultValue is returned if parameter is not found.
     *************************************************************************/
    getUrlParameter(hash: string, paramName: string, defaultValue: string) {
        var ret = defaultValue;
        if (hash.indexOf('?') >= 0) { //if there are any parameters specified
            hash = hash.substring(hash.indexOf('?') + 1);
            var params = hash.split('&');
            for (var i = 0; i < params.length; i++) {
                var param = params[i];
                if (param.toLowerCase().indexOf(paramName.toLowerCase()) === 0) {
                    var paramPair: string[] = param.split('=');
                    if (paramPair.length == 2) {
                        ret = paramPair[1];
                    }
                }
            }
        }
        return ret;
    }
}
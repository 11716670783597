import { animate, state, style, transition, trigger } from '@angular/animations';
import { KeyValue } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { AlertsService } from '../../services/alerts-service';
import { ApiService } from '../../services/api-service';
import { Utils } from '../../services/utils';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'survey',
  templateUrl: 'survey.component.html',
  animations: [

    // the fade-in/fade-out animation.
    trigger('fadeInThanks', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(600)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 }))
      )

    ]),
    trigger('fadeInQuestions', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(900)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(600, style({ opacity: 0 }))
      )

    ])

  ]
})
export class SurveyComponent extends ComponentBase {

  surveySimple: FormGroup;
  surveyExtended: FormGroup;

  disableSimple: boolean = false;
  showExtended: boolean = false;
  disableExtended: boolean = false;

  extendedCompleted: boolean = false;

  options: {
    surveyHelpful: Array<KeyValue<string, string>>,
    surveyExperience: Array<KeyValue<string, string>>,
    surveyReturn: Array<KeyValue<string, string>>,
    surveySelf: Array<KeyValue<string, string>>,
  }

  get surveyHelpful(): AbstractControl {
    return this.surveySimple.get('surveyHelpful');
  }
  get surveyExperience(): AbstractControl {
    return this.surveyExtended.get('surveyExperience');
  }
  get surveyExperienceOther(): AbstractControl {
    return this.surveyExtended.get('surveyExperienceOther');
  }
  get surveyReturn(): AbstractControl {
    return this.surveyExtended.get('surveyReturn');
  }
  get surveySelf(): AbstractControl {
    return this.surveyExtended.get('surveySelf');
  }

  constructor(private _utils: Utils, private _alertsService: AlertsService, private _apiService: ApiService) {

    super();

    this.options = {
      surveyHelpful: [{
        key: "1",
        value: "survey.surveyHelpful.option1"
      }, {
        key: "2",
        value: "survey.surveyHelpful.option2"
      }, {
        key: "3",
        value: "survey.surveyHelpful.option3"
      }, {
        key: "4",
        value: "survey.surveyHelpful.option4"
      }, {
        key: "5",
        value: "survey.surveyHelpful.option5"
      }],
      surveyExperience: [{
        key: "1",
        value: "survey.surveyExperience.option1"
      }, {
        key: "2",
        value: "survey.surveyExperience.option2"
      }, {
        key: "3",
        value: "survey.surveyExperience.option3"
      }, {
        key: "4",
        value: "survey.surveyExperience.option4"
      }],
      surveyReturn: [{
        key: "1",
        value: "survey.surveyReturn.option1"
      }, {
        key: "2",
        value: "survey.surveyReturn.option2"
      }, {
        key: "3",
        value: "survey.surveyReturn.option4"
      }, {
        key: "4",
        value: "survey.surveyReturn.option5"
      }],
      surveySelf: [{
        key: "1",
        value: "survey.surveySelf.option1"
      }, {
        key: "2",
        value: "survey.surveySelf.option2"
      }, {
        key: "3",
        value: "survey.surveySelf.option3"
      }]
    }

  }

  ngOnInit(): void {
    this.surveySimple = new FormGroup({
      'surveyHelpful': new FormControl('', [Validators.required])
    });

    this.surveyExtended = new FormGroup({
      'surveyExperience': new FormControl("", [Validators.required]),
      'surveyExperienceOther': new FormControl("", [Validators.maxLength(100)]),
      'surveyReturn': new FormControl("", [Validators.required]),
      'surveySelf': new FormControl("", [Validators.required])
    });

    this.surveySimple.controls['surveyHelpful'].valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((value) => {
      if (this._utils.nullOrEmpty(value) || value === "0" || value === 0) {
        return;
      }
      this.sendSurveySimple();
    });

  }

  sendSurveySimple(): void {
    this.disableSimple = true;
    let data = {
      surveyHelpful: parseInt(this.surveyHelpful.value)
    }
    this._apiService.sendSurveySimple(data).then(() => {
      this.showExtended = true;
    }, error => {
      this._alertsService.addExistingAlert(error);
    });
  }

  sendSurveyExtended(): void {
    let data = {
      surveyExperience: parseInt(this.surveyExperience.value),
      surveyReturn: parseInt(this.surveyReturn.value),
      surveySelf: parseInt(this.surveySelf.value),
    }
    let other = this.surveyExperienceOther.value;

    if (!this._utils.nullOrEmpty(other)) {
      data['surveyExperienceOther'] = other;
    }

    this._apiService.sendSurveyExtended(data).then(() => {
      this.disableExtended = true;
      this.extendedCompleted = true;
    }, error => {
      this._alertsService.addExistingAlert(error);
    });
  }

}
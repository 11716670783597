import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'form-textbox',
  templateUrl: 'form-textbox.component.html',
  styleUrls: []
})
export class FormTextboxComponent extends ComponentBase {

  private _sizes = ["", "lg"];

  private _hasValueOrFocus: boolean;
  get hasValueOrFocus(): boolean {
    return this._hasValueOrFocus;
  }

  @Input() label: string;

  @Input() group: FormGroup;
  @Input() name: string;

  @Input() disable: boolean;
  @Input() size?: string = "";

  constructor() {

    super();

    if (!this._sizes.includes(this.size)) {
      this.size = "";
    }

  }

  formInputFocus() {
    this._hasValueOrFocus = true;
  }

  formInputBlur() {
    if (this.group.get(this.name).value === null || String(this.group.get(this.name).value).length <= 0) {
      this._hasValueOrFocus = false;
    } else {
      this._hasValueOrFocus = true;
    }
  }

}
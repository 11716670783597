import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './api-service';
import { Utils } from './utils';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    private _hasValidId: boolean = false;
    get hasValidId(): boolean {
        return this._hasValidId;
    }

    constructor(private _router: Router, private _apiService: ApiService, private _utils: Utils) {
        this._apiService.hasValidId.subscribe((next) => {
            this._hasValidId = next;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (route.url.length && route.url[0].path === "download") {
            // if no guid on path, redirect to main page
            if (!route.params.hasOwnProperty('reportGuid') || this._utils.nullOrEmpty(route.params.reportGuid)) {
                this._router.navigate(['']);
            }
        } else if (!this._hasValidId) {
            this._router.navigate(['']);
        }
        return true;
    }

}
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Lightbox, LightboxService } from '../../services/lightbox-service';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'lightbox',
  templateUrl: 'lightbox.component.html',
  styleUrls: [],
  animations: [

    // the fade-in/fade-out animation.
    trigger('fadeIn', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(100)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(100, style({ opacity: 0 }))
      )

    ])

  ]
})
export class LightboxComponent extends ComponentBase implements OnDestroy, OnInit {

  @Input() lightboxTitle: string;
  @Input() lightboxId: string;
  @Input() preventClose?: boolean = false;

  lightbox: Lightbox;

  constructor(private service: LightboxService) {
    super();
  }

  ngOnInit(): void {
    this.lightbox = {
      lightboxId: this.lightboxId,
      show: false
    };

    this.service.register(this.lightbox);

    this.service.lightboxes.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      let found = this.service.get(this.lightboxId);
      if (found !== null) {
        this.lightbox.show = found.show;
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.service.unregister(this.lightboxId);
  }
    
  close(): void {
    if (!this.preventClose) {
      this.service.toggle(this.lightboxId);
    }
  }

}
import { Pipe, IterableDiffer, IterableDiffers, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse',
  pure: false,
})
export class ReversePipe implements PipeTransform {
  differ: IterableDiffer<any>;
  cached: Array<any>;

  constructor(private _differs: IterableDiffers) {
    this.differ = this._differs.find([]).create(null);
  }

  transform(value) {
    const changes = this.differ.diff(value);
    if (changes) {
      this.cached = value.slice().reverse();
    }
    return this.cached;
  }
}

﻿import { OnDestroy } from "@angular/core";
import { Subject } from 'rxjs';

export abstract class ComponentBase implements OnDestroy {

  protected destroyed$ = new Subject<boolean>();

  protected constructor() { }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}

import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Alert } from '../../models/alert.model';
import { AlertsService } from '../../services/alerts-service';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'alerts',
  templateUrl: 'alerts.component.html',
  styleUrls: []
})
export class AlertsComponent extends ComponentBase {

  public alerts: Array<Alert>;

  constructor(private _alertsService: AlertsService) {

    super();

    this.alerts = [];

    this._alertsService.alerts$.pipe(takeUntil(this.destroyed$)).subscribe(next => {
        this.alerts = next;
    });

  }

  close(alert: Alert){
    this._alertsService.remove(alert.id);
  }

  getAlertClass(type: string){
     return "alert-notification-" + type;
  }

}
import { Injectable } from "@angular/core";
import { Utils } from './utils';
import { BehaviorSubject } from "rxjs";
import { Alert } from "../models/alert.model";


@Injectable()
export class AlertsService {
    private _utils: Utils;
    private _index: number = 0;
    private _alerts: Alert[];

    // $ at end of variable name standard practice for observables
    public alerts$: BehaviorSubject<Array<Alert>>;
    public latest$: BehaviorSubject<Alert>;

    get alerts(): Alert[] {
        return this._alerts;
    }

    constructor(utils: Utils) {
        this._utils = utils;
        this._alerts = [];

        this.alerts$ = new BehaviorSubject<Array<Alert>>(this._alerts);
        this.latest$ = new BehaviorSubject<Alert>(null);
    }

    remove(id: number) {
        let foundIndex = -1;
        for (let i = 0, ii = this._alerts.length; i < ii; i++) {
            if (this._alerts[i].id === id) {
                foundIndex = i;
            }
        }
        if (foundIndex > -1) {
            this._alerts.splice(foundIndex, 1);
        }

        this.alerts$.next(this._alerts);
        this.latest$.next(this.getLatest());
    }

    add(message: string, type: string, timeout?: number) {
        let id = this._index;
        let alert = new Alert(message, type, id);

        this._alerts.push(alert);
        this._index += 1;

        if (this._utils.nullOrEmpty(timeout)) {
            switch (type) {
                case "w":
                    timeout = 10000;
                    break;
                case "e":
                    timeout = 0;
                    break;
                case "s":
                default:
                    timeout = 4000;
                    break;
            }
        }

        if (timeout > 0) {
            setTimeout(() => {
                this.remove(id);
            }, timeout);
        }

        this.alerts$.next(this._alerts);
        this.latest$.next(this.getLatest());
    }

    addExistingAlert(alert: Alert){
        this.add(alert.message, alert.type);
    }

    getLatest(): Alert {
        if (this._alerts.length === 0) {
            return null;
        }
        return this._alerts[this._alerts.length - 1];
    }
}
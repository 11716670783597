import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// import libraries
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxCaptchaModule } from 'ngx-captcha';

// services
import { AuthGuard } from '../services/auth-guard';
import { ApiService } from '../services/api-service';
import { AlertsService } from '../services/alerts-service';
import { Utils } from '../services/utils';

// page components
import { AppComponent } from './app.component';
import { HomeComponent } from '../components/pages/home/home.component';
import { DenialComponent } from '../components/pages/denial/denial.component';
import { FurtherStepsComponent } from '../components/pages/furthersteps/furthersteps.component';

// small components
import { CollapsiblePanelComponent } from '../components/collapsible-panel/collapsible-panel.component';
import { FormTextboxComponent } from '../components/form-controls/form-textbox/form-textbox.component';
import { FormErrorsComponent } from '../components/form-controls/form-errors/form-errors.component';
import { FormSelectComponent } from '../components/form-controls/form-select/form-select.component';
import { AlertsComponent } from '../components/alerts/alerts.component';

// models
import { ReversePipe } from '../pipes/reverse.pipe';
import { FormTextboxMaskComponent } from '../components/form-controls/form-textbox-mask/form-textbox-mask.component';
import { FormPhoneNumberComponent } from '../components/form-controls/form-phonenumber/form-phonenumber.component';
import { DisableControlDirective } from '../directives/disable-control.directive';
import { HdcShowDirective } from '../directives/hdc-show.directive';
import { HdcHideDirective } from '../directives/hdc-hide.directive';
import { SurveyComponent } from '../components/survey/survey.component';
import { SurveyRadioComponent } from '../components/form-controls/survey-radio/survey-radio.component';
import { LightboxComponent } from '../components/lightbox/lightbox.component';
import { LightboxService } from '../services/lightbox-service';
import { DownloadComponent } from '../components/pages/download/download.component';
import { FinishComponent } from '../components/pages/finish/finish.component';
import { CookieService } from '../services/cookie-service';
import { RouterModule } from '@angular/router';
import { ContextInterceptor } from '../interceptors/context.interceptor';

@NgModule({
  declarations: [
    AppComponent, HomeComponent, DenialComponent, FurtherStepsComponent, DownloadComponent, FinishComponent, 
    CollapsiblePanelComponent, FormTextboxComponent, FormTextboxMaskComponent, FormSelectComponent, 
    FormPhoneNumberComponent, FormErrorsComponent, AlertsComponent, ReversePipe, DisableControlDirective, 
    SurveyComponent, SurveyRadioComponent, LightboxComponent, HdcShowDirective, HdcHideDirective
  ],
  imports: [
    ReactiveFormsModule, BrowserModule, FormsModule, AppRoutingModule, HttpClientModule, TextMaskModule, 
    BrowserAnimationsModule, RouterModule, NgxCaptchaModule, 
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ApiService,
    AuthGuard,
    Utils,
    AlertsService,
    LightboxService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContextInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
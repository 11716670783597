import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface Lightbox {
    lightboxId: string;
    show: boolean;
}

@Injectable()
export class LightboxService {
    _lightboxes: Lightbox[];
    lightboxes: BehaviorSubject<Lightbox[]> = new BehaviorSubject<Lightbox[]>([]);

    constructor(){
        this._lightboxes = [];
    }

    register(lightbox: Lightbox) {
        if (this.get(lightbox.lightboxId) === null){
            this._lightboxes.push(lightbox);
            this.lightboxes.next(this._lightboxes);
        }
    }

    unregister(lightboxId: string){
        for (let i = 0, ii = this._lightboxes.length; i < ii; i++) {
            if (this._lightboxes[i].lightboxId === lightboxId) {
                this._lightboxes.splice(i, 1);
                this.lightboxes.next(this._lightboxes);
                return;
            }
        }
    }

    get(lightboxId: string): Lightbox{
        for (let i = 0, ii = this._lightboxes.length; i < ii; i++){
            if (this._lightboxes[i].lightboxId === lightboxId){
                return this._lightboxes[i];
            }
        }
        return null;
    }

    show(lightboxId: string) {
        for (let i = 0, ii = this._lightboxes.length; i < ii; i++) {
            if (this._lightboxes[i].lightboxId === lightboxId) {
                this._lightboxes[i].show = true;
                this.lightboxes.next(this._lightboxes);
                return;
            }
        }
    }

    hide(lightboxId: string) {
        for (let i = 0, ii = this._lightboxes.length; i < ii; i++) {
            if (this._lightboxes[i].lightboxId === lightboxId) {
                this._lightboxes[i].show = false;
                this.lightboxes.next(this._lightboxes);
                return;
            }
        }
    }

    toggle(lightboxId: string) {
        for (let i = 0, ii = this._lightboxes.length; i < ii; i++) {
            if (this._lightboxes[i].lightboxId === lightboxId) {
                this._lightboxes[i].show = !this._lightboxes[i].show;
                this.lightboxes.next(this._lightboxes);
                return;
            }
        }
    }

}
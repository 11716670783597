import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { OneTrustService } from './onetrust-service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  private _validCookies: string[] = null;

  constructor(oneTrustService: OneTrustService) {
    oneTrustService.afterOneTrustLoaded.subscribe(OneTrust => {
      this.updateCookieConsent(OneTrust);
      OneTrust.OnConsentChanged(e => this.updateCookieConsent(OneTrust));
    });
  }

  set(key: string, value: string, expires?: Date): void {

    if (this._validCookies && this._validCookies.filter(x => x === key).length !== 0) {
      let cookieValue = `${key}=${value}`;
      if (expires) cookieValue += `;expires='${expires.toUTCString()}'`
      document.cookie = cookieValue;
    }

  }

  setWithExpiryInYears(key: string, value: string, expires: number) {
    this.setWithExpiryInDays(key, value, expires * 365);
  }

  setWithExpiryInDays(key: string, value: string, expires: number) {
    this.setWithExpiryInHours(key, value, expires * 24);
  }

  setWithExpiryInHours(key: string, value: string, expires: number) {
    this.setWithExpiryInMinutes(key, value, expires * 60);
  }

  setWithExpiryInMinutes(key: string, value: string, expires: number) {
    this.setWithExpiryInSeconds(key, value, expires * 60);
  }

  setWithExpiryInSeconds(key: string, value: string, expires: number) {
    this.setWithExpiryInMiliseconds(key, value, expires * 1000);
  }

  setWithExpiryInMiliseconds(key: string, value: string, expires: number) {
    var expireDate = new Date();
    var time = expireDate.getTime() + expires;
    expireDate.setTime(time);
    
    this.set(key, value, expireDate);
  }

  get(key: string): string {
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const pairs: string[] = decodedCookie.split(/;\s*/);

    const prefix = `${key}=`;
    for (const pair of pairs) {
      if (pair.indexOf(prefix) == 0) {
        return pair.substring(prefix.length);
      }
    }
    return "";
  }

  remove(key: string): void {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
  }

  private updateCookieConsent(OneTrust) {

    // Determine the consent group id and the cookies in that consent group in OneTrust
    const cookieGroups = [];
    OneTrust.GetDomainData().Groups.forEach(group => {
      cookieGroups.push({
        id: group.OptanonGroupId,
        cookies: group.Cookies.map(x => x.Name)
      });
    });

    // Read the cookie in the format "value1=xyz&value2=xyz&groups=C0001:1,C0003:0" where C000N is a consent group and 
    // the suffix :0/1 is a boolean indicating user consent
    const consentInfo = this.get(environment.oneTrustCookieName).split('&').find(x => x.startsWith('groups=')).substring('groups='.length);
    const validGroups = consentInfo.split(',').filter(x => x.endsWith(':1')).map(x => x.substring(0, x.length - 2));

    // The "validGroups" variable now looks something like ['C0001', 'C0003'] depending on which options the user has chosen.
    // Remove any cookies that the user has NOT consented to and populate the this._validCookies array with allowed cookie values.
    this._validCookies = [];
    cookieGroups.forEach(group => {
      const isValidCookieGroup = validGroups.filter(x => x === group.id).length !== 0
      if (isValidCookieGroup) {
        this._validCookies = [...this._validCookies, ...group.cookies];
      } else {
        group.cookies.forEach(key => {
          this.remove(key)
        });
      }
    });

  }

}  
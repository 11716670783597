export const environment = {
  production: true,
  apiEndpoint: '/api',
  env: 'prod',
  siteKey: "6Ld5BZkUAAAAAAM3E5qXzm-0vAZcHNVfUjsJ2Srq",
  inactiveTimeoutMins: 29,
  oneTrustCookieName: 'OptanonConsent',
  NAME_REGEX: /^([A-Za-z ])+$/,
  PHONE_REGEX: /(\+1[ ]?)?(([0-9]{3})|(\([0-9]{3}\)))[ ]?([0-9]{3})([ ])|(-)?[0-9]{4}/,
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};
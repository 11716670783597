import { Component, Input } from '@angular/core';
import { FormTextboxComponent } from '../form-textbox/form-textbox.component';

@Component({
  selector: 'form-textbox-mask',
  templateUrl: 'form-textbox-mask.component.html',
  styleUrls: []
})
export class FormTextboxMaskComponent extends FormTextboxComponent {

  @Input() mask: Array<any>;

  constructor(){
    super();
  }

}
import { Component, Input } from '@angular/core';
import { FormTextboxComponent } from '../form-textbox/form-textbox.component';

export interface SelectOption {
  key: string,
  value: any
}

@Component({
  selector: 'form-select',
  templateUrl: 'form-select.component.html',
  styleUrls: []
})
export class FormSelectComponent extends FormTextboxComponent {

  @Input() options: Array<SelectOption>;
  @Input() key?: string = "key";
  @Input() value?: string = "value";
  
  constructor() {
    super();
  }
  
}
import { KeyValue } from "@angular/common";
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'survey-radio',
  templateUrl: 'survey-radio.component.html',
  styleUrls: []
})
export class SurveyRadioComponent extends ComponentBase {

  @Input() group: FormGroup;
  @Input() name: string;
  @Input() items: Array<KeyValue<string, string>>;
  @Input() showNumbers: boolean = false;
  @Input() format: string = "buttons";

  constructor() {
    super();
  }

}
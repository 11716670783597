import 'babel-polyfill';
import 'zone.js/dist/zone';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

declare var process : {
  env: {
    NODE_ENV: string
  }
}

import { enableProdMode } from '@angular/core';

if (process.env.NODE_ENV == "production") {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertsService } from '../../../services/alerts-service';
import { ApiService, CallbackRequest, CallbackSlot, RarRequest } from '../../../services/api-service';
import { ComponentBase } from '../../component-base';
import { SelectOption } from '../../form-controls/form-select/form-select.component';
import { Utils } from '../../../services/utils';

@Component({
  selector: 'app-page-furthersteps',
  templateUrl: 'furthersteps.component.html',
  styleUrls: []
})
export class FurtherStepsComponent extends ComponentBase {

  rar: {
    disable: boolean,
    showSuccess: boolean,
    showSurvey: boolean,
    idMask: any[]
  }
  rarForm: FormGroup;

  get rarEmail() {
    return this.rarForm.get('rarEmail');
  }

  get rarIdNumber() {
    return this.rarForm.get('rarIdNumber');
  }

  displayCallback: boolean = true;

  hasCompletedSurvey: boolean = false;
  hasRequestedRar: boolean = false;
  hasRequestedCallback: boolean = false;
  private _utils = new Utils();
  showPublicSite: boolean = false;
  callback: {
    disable: boolean,
    showSuccess: boolean, // show the success message
    disableToday: boolean // disable the today option (if no slots today)
  }

  cbForm: FormGroup;

  get cbFirstName() {
    return this.cbForm.get('cbFirstName');
  }
  get cbLastName() {
    return this.cbForm.get('cbLastName');
  }
  get cbPhoneNumber() {
    return this.cbForm.get('cbPhoneNumber');
  }
  get cbCallbackDay() {
    return this.cbForm.get('cbCallbackDay');
  }
  get cbCallbackWindow() {
    return this.cbForm.get('cbCallbackWindow');
  }

  hasId: boolean;
  hideSurvey: boolean;

  _callbackWindows: {
    loaded: boolean,
    todayTimes: Array<SelectOption>,
    tomorrowTimes: Array<SelectOption>
  }

  _todayTimes: CallbackSlot[];
  _tomorrowTimes: CallbackSlot[];

  get callbackWindows() {
    return this._callbackWindows;
  }

  constructor(
    private _apiService: ApiService,
    private _alertsService: AlertsService,
    private _router: Router
  ) {

    super();

    this.rar = {
      disable: false,
      showSuccess: false,
      showSurvey: false,
      idMask: [/[^- ]/, /[^- ]/, /[^- ]/, /[^- ]/]
    };

    this.callback = {
      disable: false,
      disableToday: false,
      showSuccess: false
    };

    this._todayTimes = [];
    this._tomorrowTimes = [];

    this._callbackWindows = {
      loaded: false,
      todayTimes: [],
      tomorrowTimes: []
    }

    this.hideSurvey = this._apiService.hideSurvey;
    this.displayCallback = this._apiService.displayCallback.value;

    var hash = window.location.hash;
    this.showPublicSite = this._utils.getUrlParameter(hash, "fromPublicSite", "false").toLowerCase() == "true";

    this._apiService.displayCallback.pipe(takeUntil(this.destroyed$)).subscribe(next => {
      this.displayCallback = next;
    });

    this._apiService.hasCompletedSurvey.pipe(takeUntil(this.destroyed$)).subscribe(next => {
      this.hasCompletedSurvey = next;
    });

    this._apiService.hasRequestedCallback.pipe(takeUntil(this.destroyed$)).subscribe(next => {
      this.hasRequestedCallback = next;
      if (next === true) {
        this.callback.disable = true;
      }
    });

    this._apiService.hasRequestedRar.pipe(takeUntil(this.destroyed$)).subscribe(next => {
      this.hasRequestedRar = next;
      if (next) {
        this.rar.disable = true;
      }
    });

    this.getCallbackWindows();

  }

  ngOnInit(): void {
    this.hasId = this._apiService.hasId;

    if (this._apiService.hasId) {
      this.rarForm = new FormGroup({
        rarEmail: new FormControl("", [
          Validators.required,
          Validators.pattern(environment.EMAIL_REGEX)
        ]),
        rarIdNumber: new FormControl("", [
          Validators.required,
          Validators.maxLength(4),
          Validators.minLength(4)
        ])
      });
    } else {
      this.rarForm = new FormGroup({
        rarEmail: new FormControl("", [
          Validators.required,
          Validators.pattern(environment.EMAIL_REGEX)
        ]),
        rarIdNumber: new FormControl("")
      });
    }


    this.cbForm = new FormGroup({
      cbFirstName: new FormControl("", [
        Validators.required,
        Validators.pattern(environment.NAME_REGEX)
      ]),
      cbLastName: new FormControl("", [
        Validators.required,
        Validators.pattern(environment.NAME_REGEX)
      ]),
      cbPhoneNumber: new FormControl("", [
        Validators.required,
        Validators.pattern(environment.PHONE_REGEX)
      ]),
      cbCallbackDay: new FormControl("today", [
        Validators.required,
        Validators.pattern(/^today|tomorrow$/)
      ]),
      cbCallbackWindow: new FormControl("", [
        Validators.required
      ])

    })
  }

  public requestRar() {
    this.rar.disable = true;

    let data: RarRequest = {
      emailAddress: this.rarEmail.value
    }

    if (this._apiService.hasId) {
      data['idNumber'] = this.rarIdNumber.value
    }

    this._apiService.requestRar(data).then(() => {
      this.rar.showSuccess = true;
      this.rar.showSurvey = true;
    }, error => {
      this.rar.disable = false;
      this._alertsService.addExistingAlert(error);
    });
  }

  public getCallbackWindows() {
    // clear the existing callback options
    this._todayTimes = [];
    this._tomorrowTimes = [];
    this._callbackWindows.todayTimes = [];
    this._callbackWindows.tomorrowTimes = [];

    this._apiService.getCallbackWindows().then(response => {
      this._todayTimes = response.todayTimes;
      this._tomorrowTimes = response.tomorrowTimes;

      if (response.todayTimes.length) {
        // if we have times for today, generate the select options
        this.callback.disableToday = false;
        for (let time of response.todayTimes) {
          let displayDate = `${moment(time.startTime).format("h A")} - ${moment(time.endTime).format("h A")}`;
          this._callbackWindows.todayTimes.push({
            key: time.startTime,
            value: displayDate
          });
        }
      } else {
        // otherwise disable the 'today' option and set the select to 'tomorrow'
        this.callback.disableToday = true;
        this.cbCallbackDay.setValue("tomorrow");
      }

      // generate the select options for the tomorrow times
      for (let time of response.tomorrowTimes) {
        let displayDate = `${moment(time.startTime).format("h A")} - ${moment(time.endTime).format("h A")}`;
        this._callbackWindows.tomorrowTimes.push({
          key: time.startTime,
          value: displayDate
        });
      }

      this._callbackWindows.loaded = true;
    }, error => {
      this._callbackWindows.loaded = false;
      this._alertsService.add(error, "e");
    });
  }

  public requestCallback() {
    this.callback.disable = true;

    let data: CallbackRequest = {
      firstName: this.cbFirstName.value,
      lastName: this.cbLastName.value,
      phoneNumber: this.cbPhoneNumber.value.replace(/[\(\)\-\ ]/g, ""),
      callbackDay: this.cbCallbackDay.value,
      callbackWindowStart: null,
      callbackWindowEnd: null
    }

    let search: CallbackSlot[];

    if (data.callbackDay === "today") {
      search = this._todayTimes;
    } else {
      search = this._tomorrowTimes;
    }

    for (var i = 0, ii = search.length; i < ii; i++) {
      if (search[i].startTime === this.cbCallbackWindow.value) {
        data.callbackWindowStart = search[i].startTime;
        data.callbackWindowEnd = search[i].endTime;
      }
    }

    this._apiService.requestCallback(data).then(() => {
      this.callback.showSuccess = true;
      // if the survey hasn't been completed yet, hide it as users receive a telephone survey
      if (this.rar.showSuccess === false) {
        this.hideSurvey = true;
      }
    }, error => {
      this.callback.disable = false;
      this._alertsService.addExistingAlert(error);
    });
  }

  /**
   * Gets the available callback windows for the selected day
   * @returns {Array<SelectOption>} An array of select control formatted options for selecting the time slot
   */
  public callbackWindowsForDay(): Array<SelectOption> {
    if (this._callbackWindows === null) {
      return [];
    }

    switch (this.cbForm.get('cbCallbackDay').value) {
      case "today":
        return this.callbackWindows.todayTimes;
      case "tomorrow":
        return this.callbackWindows.tomorrowTimes;
    }
  }

  /**
   * Clears the callback window select box when changing the date
   */
  public clearCallbackWindow(): void {
    this.cbForm.get('cbCallbackWindow').setValue("");
    }
    /*************************************************************************
     * Author:  KWOO
     * Date 2021-09-20
     * Summary:  Get the parameters to add to the url links.
     *************************************************************************/
    private getNavUrlParameters() {
        // Set the fromPublicSite parameters for the url if fromPublicSite exists in query parameters.
        var hash = window.location.hash;
        var retValue: string = this._utils.getUrlParameter(hash, "fromPublicSite", "false");
        var navUrlParameters: string = retValue.toLowerCase() === "true" ? "?fromPublicSite=true" : "";

        return navUrlParameters;
    }
  /**
   * Redirects the user to the finish page.
   */
  public finish(): void {
      this._router.navigateByUrl('/finish' + this.getNavUrlParameters());
  }

}
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertsService } from '../../../services/alerts-service';
import { ApiService } from '../../../services/api-service';
import { Utils } from '../../../services/utils';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'app-page-home',
  templateUrl: 'home.component.html'
})
export class HomeComponent extends ComponentBase {

  siteKey: string = environment.siteKey;
  lang: string;
  receiptImage: string;

  homeForm: FormGroup;
  disableHomeForm: boolean;

  private _utils = new Utils();

  get transactionId(): AbstractControl {
    return this.homeForm.get('transactionId');
  }
  
  get recaptcha(): AbstractControl {
    return this.homeForm.get('recaptcha');
  }

  constructor(private _alertsService: AlertsService, private _apiService: ApiService, private _router: Router) {

    super();

    this.homeForm = new FormGroup({
      'transactionId': new FormControl('', [
        Validators.required,
        Validators.pattern(/[0-9\-]+[A-Za-z]{3}/)
      ]),
      'recaptcha': new FormControl('', [
        Validators.required
      ])
    })
    this.disableHomeForm = false;
    
    this.lang = _apiService.selectedLang.value;
    this._apiService.selectedLang.pipe(takeUntil(this.destroyed$)).subscribe(next => {
        this.lang = next;
        this.receiptImage = this.lang === 'fr-CA' ? '/assets/images/receipt_fr-ca.png' : '/assets/images/receipt.png';
    });

    this.receiptImage = this.lang === 'fr-CA' ? '/assets/images/receipt_fr-ca.png' : '/assets/images/receipt.png';
    //if we have the transaction ID in the "query string" then auto-populate the textbox
      var hash = window.location.hash; //in Angular the "query string parameters" actually go after the hashtag
      var retValue:string = this._utils.getUrlParameter(hash, "transactionId", "");
      if (retValue != "") {
          this.homeForm.get('transactionId').setValue(retValue);
      }      
    }
    /*************************************************************************
     * Author:  KWOO
     * Date 2021-09-20
     * Summary:  Get the parameters to add to the url links.
     *************************************************************************/
    private getNavUrlParameters() {
        // Set the fromPublicSite parameters for the url if fromPublicSite exists in query parameters.
        var hash = window.location.hash;
        var retValue: string = this._utils.getUrlParameter(hash, "fromPublicSite", "false");
        var navUrlParameters: string = retValue.toLowerCase() === "true" ? "?fromPublicSite=true" : "";

        return navUrlParameters;
    }
    continue() {
        
        if (this.transactionId === null || this.transactionId.value === "") {
          this._alertsService.add("home.error.transactionId", "w");
          return;
        }
        if (this.recaptcha === null || this.recaptcha.value === "") {
          this._alertsService.add("home.error.recaptcha", "w");
          return;
        }
        this.disableHomeForm = true;

        let transactionIdTrimmed = this.transactionId.value.trim();
        this._apiService.validateTransactionId(transactionIdTrimmed, this.recaptcha.value).then(response => {
          this._router.navigateByUrl("/denialreasons" + this.getNavUrlParameters());
        }, error => {
          this._alertsService.add(error.message, error.type);
          this.disableHomeForm = false;
        });
    }

}
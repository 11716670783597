/**
 * A model of an alert
 * @property {string} message The message to display in the Alert
 * @property {string} type The type of the Alert. "s" for success, "w" for warning and "e" for error.
 * @property {number} id The Alert ID. Should only be assigned by ALertService.
 */
export class Alert {
    message: string;
    type: string;
    id?: number;

    /**
     * 
     * @param message The message to display in the Alert
     * @param type The type of the Alert. "s" for success, "w" for warning and "e" for error.
     * @param id THe Alert ID. Should only be assigned by AlertsService.
     */
    constructor(message: string, type: string, id?: number) {
        this.message = message;
        this.type = type;
        this.id = id;
    }
}
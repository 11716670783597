import { Component } from '@angular/core';
import { FormTextboxComponent } from '../form-textbox/form-textbox.component';

@Component({
  selector: 'form-phonenumber',
  templateUrl: 'form-phonenumber.component.html',
  styleUrls: []
})
export class FormPhoneNumberComponent extends FormTextboxComponent {

  mask: Array<any>;

  constructor() {

    super();

    this.mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  }

}
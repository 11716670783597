﻿import { Injectable } from '@angular/core';
import { AsyncSubject, interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OneTrustService {

  private oneTrustLoaded = new Subject<boolean>();
  public afterOneTrustLoaded = new AsyncSubject<any>();

  constructor() {
    interval(500).pipe(takeUntil(this.oneTrustLoaded)).subscribe(() => {
      if ((window as any).OneTrust && this.hasOneTrustCookie()) {
        this.oneTrustLoaded.next(true);
        this.oneTrustLoaded.complete();
        this.afterOneTrustLoaded.next((window as any).OneTrust);
        this.afterOneTrustLoaded.complete();
      }
    });
  }

  private hasOneTrustCookie(): boolean {
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const pairs: string[] = decodedCookie.split(/;\s*/);
    return pairs.filter(x => x.indexOf(`${environment.oneTrustCookieName}=`) === 0).length !== 0;
  }

}  
import { AfterViewInit, Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AlertsService } from '../../../services/alerts-service';
import { ApiService } from '../../../services/api-service';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'app-page-download',
  templateUrl: 'download.component.html',
  styleUrls: []
})
export class DownloadComponent extends ComponentBase implements AfterViewInit {

  downloadLink: string;
  loading: boolean = false;
  showDownload: boolean = false;
  lang: string;
  siteKey: string;

  form: FormGroup;

  get recaptcha(): AbstractControl {
    return this.form.get('recaptcha');
  }

  constructor(private _route: ActivatedRoute, private _alertsService: AlertsService, private _apiService: ApiService) {

    super();

    this.form = new FormGroup({
      'recaptcha': new FormControl('', Validators.required)
    });
    this.lang = _apiService.getCurrentLanguage();

    _apiService.selectedLang.pipe(takeUntil(this.destroyed$)).subscribe(next => {
      this.lang = next;
    });

    this.siteKey = environment.siteKey;

  }

  ngAfterViewInit(): void {
  }

  verify($event): void {
    this.recaptcha.disable();
    this.loading = true;

    let data = {
      reportGuid: this._route.snapshot.params.reportGuid,
      recaptchaResponse: $event,
      lang: this._apiService.getCurrentLanguage()
    }

    this._apiService.downloadRar(data).then(response => {
      this.downloadLink = response;
      this.showDownload = true;
      this.loading = false;
    }, error => {
      this._alertsService.addExistingAlert(error);
      this.recaptcha.enable();
      this.loading = false;
    })
  }

  reset(): void {
    this.showDownload = false;
    this.downloadLink = "";
    this.recaptcha.enable();
    this.loading = false;
  }

}